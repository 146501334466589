import React, { useRef, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { ProgressBar } from "react-bootstrap";
import { Modal } from "react-responsive-modal";
import { X } from 'react-feather';
import ReactPaginate from "react-paginate";
import Slider from "react-slick";
import Img from "gatsby-image";

import useWindowDimensions from "@helper/screens";
import Layout from "@components/Layout";
import ArrowLeft from "@assets/images/icon/arrow-circle-left-black.svg";
import ArrowLeftWhite from "@assets/images/icon/arrow-circle-left-white.svg";
import css from "@cssmodules/portofolio.module.scss";
import "react-responsive-modal/styles.css";


const Portofolio = () => {
  // state to hold index of pages.
  const [activePage, setActivePage] = useState(0);
  // state to hold progress of Progress Bar (0 - 100)
  const [top5Progress, setTop5Progress] = useState((0 / 4) * 100);
  // state to hold active portofolio modal.
  const [activePortofolio, setActivePortofolio] = useState(null);
  // state modal open/close
  const [modal, setModal] = useState(false);
  // state to hold modal of active index
  const [activeIndex, setActiveIndex] = useState(0);
  // getting user screen dimension
  const { width: screenWidth } = useWindowDimensions();
  // slider top 5 ref
  const top5Ref = useRef();
  // slider modal ref
  const modalRef = useRef();
  // showing portofolio images according user screen width.
  const showPortofolios = screenWidth < 1024 ? 4 : 8;

  // graphql query for getting all images in portofolio
  const { desktopBG, mobileBG, portofolio } = useStaticQuery(graphql`
    query Portofolio {
      desktopBG: file(
        relativePath: { eq: "bg/desktop/bg-hero-portofolio.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2024, maxHeight: 1047, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobileBG: file(relativePath: { eq: "bg/mobile/bg-hero-portofolio.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 751, maxHeight: 1334, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      portofolio: allFile(
        filter: { relativeDirectory: { regex: "/(portofolio)/" } }
        sort: { fields: [relativeDirectory, name], order: ASC }
      ) {
        group(field: relativeDirectory) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 250, maxHeight: 340, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  // settings wheter mobile/desktop images on hero.
  const bg = [
    mobileBG.childImageSharp.fluid,
    {
      ...desktopBG.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  // render function for top 5 fav portos.
  const renderTop5 = () => {
    const settings = {
      slidesToShow: screenWidth < 1024 ? 2 : 3,
      slidesToScroll: 1,
      arrows: false,
      infinite: true,
      beforeChange: function (_, index) {
        var progress = (index / 4) * 100;
        setTop5Progress(progress);
      },
    };

    var currIndex = top5Progress * 4 /100;

    const nextSlide = e => {
      e.preventDefault();
      top5Ref.current.slickNext();
    };

    const prevSlide = e => {
      e.preventDefault();
      top5Ref.current.slickPrev();
    };

    var porto = [];
    if (portofolio?.group.length > 0) {
      porto = [...portofolio?.group].slice(0, 5);
    }

    return (
      <div>
        <Slider className={css.slider} {...settings} ref={top5Ref}>
          {porto.map((group, index) => {
            const edge = group.edges.filter(
              (edge, _) => edge.node.name === "main"
            )[0];

            return (
              <div
                className={`${css.col} ${index === currIndex ? css.active : ''}`}
                key={edge?.node.id}
                onClick={e => portofolioOnClick(e, group.edges)}
              >
                <Img
                  fluid={edge?.node.childImageSharp.fluid}
                  alt={edge?.node.name}
                  style={{ width: 100 + "%", height: 100 + "%" }}
                />
              </div>
            );
          })}
        </Slider>
        <div className={css.navigations}>
          <div className={css.buttonNav}>
            <button onClick={e => prevSlide(e)}>
              <img src={ArrowLeft} />
            </button>
            <button onClick={e => nextSlide(e)} className={css.right}>
              <img src={ArrowLeft} />
            </button>
          </div>
          <ProgressBar className={css.progress} now={top5Progress} />
          <div className={css.index}>
            <span>{(top5Progress / 100) * 4 + 1}</span>&nbsp;/&nbsp;5
          </div>
        </div>
      </div>
    );
  };

  // if portofolio images on click.
  const portofolioOnClick = (e, edges) => {
    e.preventDefault();

    var sort = edges.sort((a, b) => {
      return a.node.name == "main" ? -1 : b.node.name == "main" ? 1 : 0;
    });

    setActivePortofolio(sort);
    setModal(true);
  };

  // render function for portofolios.
  const renderPortofolio = () => {
    const firstIndex = activePage * showPortofolios;

    let porto = [];
    if (portofolio?.group.length > 0) {
      porto = [...portofolio?.group].slice(
        firstIndex,
        firstIndex + showPortofolios
      );
    }

    const render = porto.map((group, _) => {
      const edge = group.edges.filter(
        (edge, _) => edge.node.name === "main"
      )[0];

      return (
        <div
          className={css.col}
          key={edge?.node.id}
          onClick={e => portofolioOnClick(e, group.edges)}
        >
          <Img
            fluid={edge?.node.childImageSharp.fluid}
            alt={edge?.node.name}
            style={{ width: 100 + "%", height: 100 + "%" }}
          />
        </div>
      );
    });

    return render;
  };

  // pagination while on click.
  const paginateOnClick = page => {
    setActivePage(page.selected);
  };

  // while modal on close.
  const modalOnClose = () => {
    setModal(false);
    setActiveIndex(0);
  };

  // setting index before modal slider change
  const slideBeforeChange = (_, destinationIndex) => {
    setActiveIndex(destinationIndex);

    return destinationIndex;
  };

  // Slick modal settings.
  const slickSettings = {
    customPaging: function (i) {
      return (
        <div
          key={activePortofolio[i].node.id}
          className={`${css.img} ${activeIndex === i && css.active}`}
        >
          <Img
            fluid={
              activePortofolio[i].node.childImageSharp.fluid
                ? activePortofolio[i].node.childImageSharp.fluid
                : ""
            }
            alt="portofolio"
            style={{ width: 100 + "%", height: 100 + "%" }}
          />
        </div>
      );
    },
    className: css.slider,
    beforeChange: slideBeforeChange,
    ref: modalRef,
    dots: true,
    arrows: false,
    dotsClass: css.list,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // trigger event for modal while arrow on click.
  const modalNextSlide = e => {
    e.preventDefault();
    modalRef.current.slickNext();
  };

  // trigger event for modal while arrow on click.
  const modalPrevSlide = e => {
    e.preventDefault();
    modalRef.current.slickPrev();
  };

  // RENDER BOIS!
  return (
    <Layout>
      <header className={css.hero}>
        <div className={css.img}>
          <Img
            fluid={bg}
            alt="portofolio"
            style={{ width: 100 + "%", height: 100 + "%" }}
          />
        </div>
        <div className={css.content}>
          <h1>
            <p>
              Berikut adalah <span>5 desain terfavorit</span>
            </p>
            <p>yang sering dipilih oleh klien kami</p>
          </h1>
          <div className={css.portofolioPreview}>{renderTop5()}</div>
        </div>
      </header>

      <div className={css.portofolio}>
        <h1 className={css.title}>
          Semua <span>desain jaket</span>
        </h1>

        <div className={css.portofolioWrapper}>
          <div className={css.row}>{renderPortofolio()}</div>
          <ReactPaginate
            previousLabel={null}
            nextLabel={null}
            breakLabel={"..."}
            pageCount={Math.ceil(portofolio.group.length / showPortofolios)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={paginateOnClick}
            containerClassName={css.pagination}
            pageClassName={css.paginationSub}
            activeClassName={css.active}
          />
        </div>
      </div>

      {/* THIS IS MODAL JAKET */}
      <Modal
        open={modal}
        onClose={modalOnClose}
        classNames={{ modal: css.portoModal, modalContainer: css.modalCenter }}
        closeIcon={<X color="#fff" strokeWidth={3} />}
      >
        <div className={css.modalWrapper}>
          <Slider
            {...slickSettings}
          >
            {activePortofolio &&
              activePortofolio.map(({ node }) => (
                <div key={node.id} className={css.img}>
                  <Img
                    fluid={node.childImageSharp.fluid}
                    alt="portofolio"
                    style={{ width: 100 + "%", height: 100 + "%" }}
                  />
                </div>
              ))}
          </Slider>
          <div className={css.navigations}>
            <div className={css.buttonNav}>
              <button onClick={e => modalPrevSlide(e)}>
                <img src={ArrowLeftWhite} />
              </button>
              <button onClick={e => modalNextSlide(e)} className={css.right}>
                <img src={ArrowLeftWhite} />
              </button>
            </div>
            <div className={css.index}>
              <span>{activeIndex+1}</span>&nbsp;/&nbsp;{activePortofolio ? activePortofolio.length : 0}
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default Portofolio;
